/*Open Menu*/
// $(".open_menu").on('click', function () {
//   if ($(this).hasClass('is-active')) {
//     $(this).removeClass('is-active');
//     //close menu
//     $('#headermenu').removeClass('is-active');
//     //
//     $('.header_left').removeClass('is-active');
//     $('.header_center').removeClass('is-active');
//   }
//   else {
//     $(this).addClass('is-active');
//     //open menu
//     $('#headermenu').addClass('is-active');
//     //
//     $('.header_left').addClass('is-active');
//     $('.header_center').addClass('is-active');
//   }
// });

/*Open Menu*/
$(".menu_open button").on('click', function() {
  if($(this).hasClass('is-active')){
      $("body").css('overflow-y','scroll');
      $(this).removeClass('is-active');
      $('.headermenu').removeClass('is-active');
      $('.header_wrapper').removeClass('openmenu');
    }
    else{
      $(this).addClass('is-active');
      jQuery("body").css('overflow-y','hidden');
      $('.headermenu').addClass('is-active');
      $('.header_wrapper').addClass('openmenu');
  }
  $('.headermenu a').on('click', function() {
    setTimeout(function() {
        $(".menu_open button").removeClass('is-active');
        $('.headermenu').removeClass('is-active');
        $('.header_wrapper').removeClass('openmenu');
        $("body").css('overflow-y', 'scroll');
    }, 1000);
});
});

/*Mansonry*/
if ($(window).width() > 768) {
  var $grid = $('.gallery-wrapper').imagesLoaded(function () {
    // init Masonry after all images have loaded
    $grid.masonry({
      itemSelector: '.gallery_item',
      gutter: 30
      //  columnWidth: '.gallery_item',
      // fitWidth: true
    });
  });
}

/*Hover Menu - Add Class to all except hover item*/
$(document).ready(function() {
  $('#menu-main-menu li a').hover(function() {
    $('#menu-main-menu li a').not($(this)).addClass('not-hovered');
  }, function() {
    $('#menu-main-menu li a').removeClass('not-hovered');
  });
});

/*Custom Tabs*/
$("#custom_tabs .tabs p").on('click', function () {
  var tab_open;
  var content_open;
  if($(this).hasClass('active')){

  }else{
    $("#custom_tabs .tabs p").removeClass('active');
    $(this).addClass('active');
    tab_open=$(this).attr("data-tab");
  }
  $( ".tab_content .content" ).each(function( index ) {
      content_open=$(this).attr("data-tab");
      if(content_open == tab_open){
        $( ".tab_content .content" ).removeClass('active');
        $(this).addClass('active');
      }
  });
});


/*Add animation on page load to h1*/
$(document).ready(function () {
  setTimeout(function () {
    $('h1').addClass('is-visible');
  }, 200);
});


/* Default slider swipper */
import Swiper, { Navigation , Pagination } from 'swiper';

if($("#home-reviews").length){
    new Swiper(".comment_swipe", {
        slidesPerView: 1,
        grabCursor: true,
        direction: 'horizontal',
        speed: 1200,
        spaceBetween: 50,
        navigation: {
          nextEl: ".comment-swiper-button-next",
          prevEl: ".comment-swiper-button-prev",
        },
    });
};



if($(".home #landing").length){
  // const hfraction = document.getElementById("hfraction");
  // const hslides = document.querySelectorAll(".hswipe");
  // const hslideCount = hslides.length;
  // hfraction.innerHTML = `<span class="hfraction-count">01</span>/0${hslideCount}`;
    var hswiper = new Swiper(".home_swipe", {
      slidesPerView: 1,
      grabCursor: true,
      direction: 'horizontal',
      speed: 1200,
      spaceBetween: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // on: {
      //   slideChange: () => {
      //       hfraction.innerHTML = `<span class="hfraction-count">0${hswiper.realIndex + 1}</span>/0${hslideCount}`;
      //   }
      // },
    });
};


if($("#news").length){
    new Swiper(".news_swipe", {
        slidesPerView: 3,
        loop: true,
        grabCursor: true,
        direction: 'horizontal',
        centeredSlides: true,
        speed: 1200,
        spaceBetween: 50,
        navigation: {
          nextEl: ".news-swiper-navigation .mobile-swiper-next",
          prevEl: ".news-swiper-navigation .mobile-swiper-prev",
        },
        breakpoints: {
        1401: {
            slidesPerView: 3.4,
        },
        1281: {
          slidesPerView: 2.5,
        },
        769: {
          slidesPerView: 2,
        },
        451: {
          slidesPerView: 1
        },
        0: {
          slidesPerView: 1
        },
      },
    });
};

if($("#steps").length){
    new Swiper(".steps_swipe", {
        slidesPerView: 2,
        loop: false,
        grabCursor: true,
        direction: 'horizontal',
        mousewheelControl: true,
        centeredSlides: false,
        speed: 1200,
        spaceBetween: 80,
        breakpoints: {
        1281: {
          slidesPerView: 3.5
        },
        1025: {
          slidesPerView: 2.5
        },
        769: {
          slidesPerView: 1.5
        },
        451: {
          slidesPerView: 1,
          loop: false,
        },
        0: {
          slidesPerView: 1,
          loop: false,
        },
      },
    });
};

if($("#gallery").length){
  const hfraction = document.getElementById("hfraction");
  const hslides = document.querySelectorAll(".gallery-slide");
  const hslideCount = hslides.length;
  if (!hfraction == null) {
    hfraction.innerHTML = `<span class="hfraction-count">01</span>/0${hslideCount}`;
  }
  const gallerySwiper = new Swiper(".gallery_swipe", {
      slidesPerView: 1.8,
      grabCursor: true,
      loop:false,
      direction: 'horizontal',
      centeredSlides: false,
      speed: 1200,
      spaceBetween: 30,
      watchOverflow: true,
      navigation: {
        nextEl: ".gallery-swiper-button-next",
        prevEl: ".gallery-swiper-button-prev",
      },
      on: {
        slideChange: () => {
          if (!hfraction == null) {
            console.log(gallerySwiper.realIndex)
            hfraction.innerHTML = `<span class="hfraction-count">0${gallerySwiper.realIndex + 1}</span>/0${hslideCount}`;
          }
        }
      },
      breakpoints: {
      1281: {
        slidesPerView: 1.8
      },
      1025: {
        slidesPerView: 1.5,
      },
      769: {
        slidesPerView: 1.3,
      },
      451: {
        slidesPerView: 1
      },
      0: {
        slidesPerView: 1
      },
    },
  });
};

if ($(".instructors_swipe").length) {
  const gallerySwiper = new Swiper(".instructors_swipe", {
    slidesPerView: 4,
    grabCursor: true,
    loop: true,
    direction: 'horizontal',
    centeredSlides: false,
    speed: 1200,
    navigation: {
      nextEl: ".instructors-swiper-button-next",
      prevEl: ".instructors-swiper-button-prev",
    },
    breakpoints: {
    1440: {
      slidesPerView: 4,
      spaceBetween: 60,
    },
    1025: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
    769: {
      slidesPerView: 2,
      spaceBetween: 50,
    },
    451: {
      slidesPerView: 1
    },
    0: {
      slidesPerView: 1
    },
  },
});
}

setTimeout(function() {
  $( ".faq-wrapper .faq" ).each(function( index ) {
    var text_height=$(this).find('.text').height();
    console.log(text_height);
    $(this).find('.text').attr('data-height',text_height+'px');
  });
}, 500);

setTimeout(function(){
  $( ".faq-wrapper .faq" ).each(function( index ) {
    $(this).find('.text').css('height','0px');
  });
},1000);

$(".faq-wrapper .faq").on('click', function () {
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).find('.text').css('height','0px');
    }else{
      $(this).addClass('active');
      var height_text=$(this).find('.text').attr("data-height");
      $(this).find('.text').css('height',height_text);
    }
})

$( ".goals-wrapper .goal" ).each(function( index ) {
  var text_height=$(this).find('.text').height();
  console.log(text_height);
  $(this).find('.text').attr('data-height',text_height+'px');
});
$( ".goals-wrapper .goal" ).each(function( index ) {
  $(this).find('.text').css('height','0px');
});
$(".goals-wrapper .goal").on('click', function () {
if($(this).hasClass('active')){
    $(this).removeClass('active');
   $(this).find('.text').css('height','0px');
}else{
  $(this).addClass('active');
  var height_text=$(this).find('.text').attr("data-height");
  $(this).find('.text').css('height',height_text);
}
});


$("#course .gift a").click(function() {
    $('html, body').animate({
        scrollTop: $("#gift-this-product").offset().top
    }, 2000);
});

const headerWrapper = $('.header_wrapper');

// Scroll event handler
$(window).scroll(function () {
  if ($(window).scrollTop() > 0) {
    headerWrapper.addClass('scrolled');
  } else {
    headerWrapper.removeClass('scrolled');
  }
});

// if there is a class "single-product" on the page, then add the class "black_header" to the header
if ($(".single-product").length || $(".single-course").length) {
  headerWrapper.addClass('black_header');
}


const homeOnlineCourses = new Swiper(".home-online-courses-swiper", {
  slidesPerView: 3,
  grabCursor: true,
  direction: 'horizontal',
  spaceBetween: 50,
  navigation: {
    nextEl: '.home-online-courses-swiper-navigation .mobile-swiper-next',
    prevEl: '.home-online-courses-swiper-navigation .mobile-swiper-prev',
  },
  breakpoints: {
    1440: {
      slidesPerView: 3.5,
    },
    1024: {
      slidesPerView: 2.5,
    },
    768: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1
    },
  },
});

const homeInstructores = new Swiper(".home-speakers-swiper", {
  slidesPerView: '1.1',
  centeredSlides: true,
  spaceBetween: 20,
  grabCursor: true,
  direction: 'horizontal',
  breakpoints: {
    '450': {
      slidesPerView: 'auto',
      spaceBetween: 50,
      centeredSlides: false,
    },
  },
  navigation: {
    nextEl: '.home-speakers-swiper-navigation .mobile-swiper-next',
    prevEl: '.home-speakers-swiper-navigation .mobile-swiper-prev',
  },
});

document.addEventListener('DOMContentLoaded', function() {
  // Select all question wrappers
  const questionWrappers = document.querySelectorAll('.faq-question-wrapper');

  // Function to toggle the answer visibility
  function toggleAnswer(event) {
    // Get the associated answer wrapper
    event.currentTarget.classList.toggle('active');
    const answerWrapper = event.currentTarget.nextElementSibling;

    // Check if the answer is currently visible
    if (answerWrapper.style.height && answerWrapper.style.height !== '0px') {
      answerWrapper.style.height = '0'; // Collapse the answer
    } else {
      answerWrapper.style.height = answerWrapper.scrollHeight + 'px'; // Expand the answer
    }
  }

  // Add click event listener to each question wrapper
  questionWrappers.forEach(function(wrapper) {
    wrapper.addEventListener('click', toggleAnswer);
  });

  // Optional: set the answer height to auto after transition ends
  // const answerWrappers = document.querySelectorAll('.faq-answer-wrapper');
  // answerWrappers.forEach(function(wrapper) {
  //   wrapper.addEventListener('transitionend', function() {
  //     if (wrapper.style.height !== '0px') {
  //       wrapper.style.height = 'auto';
  //     }
  //   });
  // });
});
